import { useState } from 'react';
import tourMap from '@assets/images/tour-route_2025.svg';

export default function TourInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit mb60">
        <h3></h3>
        <div className="container_lang">
          <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
          <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
            {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Welcome to the Samsung CES VIP tour reservation page.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Our VIP tours are exclusively available for guests hosted by Samsung executives at the VP/Regional Head level or higher to ensure a flexible and premium experience.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Tours are conducted in 40 minutes and are available in both Korean and English.<br />If you are more than 10 minutes late, your reservation will be automatically marked as a no-show, and the tour will be canceled.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Timeline: [1st] 26th Nov. 2024(TUE) (KST) - 18th Dec. 2024(WED) 4pm(KST) </p>
                <p className='mb15 sp_p'><span className='sp_sp'></span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[2nd] 19st Dec. 2024(THU) 10am(KST) open – 3rd Jan. 2025(FRI) 4pm(KST) </p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Confirmation emails will be sent out sequentially starting from 27th Dec. 2024(FRI).<br/>* Application will be on hold due to limited VIP tour slots, only applications that meet the criteria will receive <br/>&nbsp;&nbsp; final approval.<br/>* For important partners, we recommend submitting applications during the first timeline.<br/>&nbsp;&nbsp; Please note that no approvals will be finalized before 18th Dec. 2024(WED).<br/>&nbsp;&nbsp; In case of schedule adjustments due to overlapping applications, we will contact you individually.<br/>&nbsp;&nbsp; Thank you for your understanding.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Tours are limited to 15 people to maintain a pleasant tour experience.<br />Please provide the exact number of participants for equipment preparation.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> If any changes after final approval, please contact the designated representative immediately via email.<br/>Heali Hwang : <a href="mailto:h0302.hwang@samsung.com" className="ft_blue ssone">h0302.hwang@samsung.com</a><br/>Minyoung Park : <a href="mailto:my1011.park@samsung.com" className="ft_blue ssone">my1011.park@samsung.com</a></p>

              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Check-in point</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> VIP tours will start at the VIP tour desk located in front of Booth #18604 in the LVCC Central Hall.<br/>The VIP desk is situated in the left corner when facing the main entrance of the Samsung booth.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Please check-in at the VIP tour desk and follow the instructions provided.</p>
                <p className='mb15 sp_p mb40'><span className='sp_sp'>-</span> Kindly adhere to the scheduled time to ensure a seamless experience for the next VIP tour group.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10 ssone sp_h'><span className="ft_blue ft_700">Check-in</span>  &nbsp;→&nbsp; Greeting  &nbsp;→&nbsp; One Samsung Vision  &nbsp;→&nbsp; Why SmartThings &nbsp;→&nbsp; Home AI &nbsp;→&nbsp; Beyond Home &nbsp;→&nbsp; <span className="ft_blue ft_700">Check-out</span></p>
              </div>
            </div>

          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Samsung CES VIP tour 예약 페이지에 오신 것을 환영합니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 당사 VIP tour는 유연한 귀빈 영접을 위해 부사장/지역총괄급 이상 삼성 임원이 직접 호스팅 하시는 투어에 한하여 신청 받고 있습니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 투어는 40분씩 국/영문 두 가지 언어로 지원되며 예약시간으로부터 10분이 지나면 자동 노쇼로 간주되어 취소됩니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 신청기한 1. 2024년 11월 26일(화) (KST) ~ 2024년 12월 18일(수) 오후 4시(KST) </p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 신청기한 2. 2024년 12월 19일(목) 오전 10시(KST) ~ 2025년 1월 3일(금) 오후 4시(KST) </p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 확정메일발송. 2024년/12월/27일/FRI부터 순차적 진행 예정<br/>* 신청 즉시 대기 상태(예약 미확정)<br/>* 한정된 tour slot으로 예약 조건에 부합되는 투어 건만 최종 승인되는 점 참고 부탁드립니다.<br/>* 중요 거래선의 경우 가급적 1차 기간 내 신청 부탁드리며 12월 18일(수)까지는 사전 확정이 어렵습니다.<br/>&nbsp;&nbsp; 부득이하게 신청된 투어 간 시간 조율이 필요할 경우 개별 연락드릴 예정이오니 이 점 사전 양해 부탁드립니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 원활한 투어를 위해 인원은 최대 15인으로 제한하고 있습니다.<br/>도슨트 장비 준비를 위해 참석자 인원을 정확히 표기 부탁드립니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 최종 승인 이후 변동 사항 발생 시, 반드시 담당자 이메일 즉시 연락 부탁드립니다.<br/>Heali Hwang : <a href="mailto:h0302.hwang@samsung.com" className="ft_blue ssone">h0302.hwang@samsung.com</a><br/>Minyoung Park : <a href="mailto:my1011.park@samsung.com" className="ft_blue ssone">my1011.park@samsung.com</a></p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Check-in point</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> LVCC Central hall #18604 당사 부스 VIP tour desk 앞에서 투어가 시작될 예정이며<br/>VIP tour desk는 삼성관 메인 입구를 바라보고 섰을 때 좌측 코너에 위치하고 있습니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> VIP tour desk에서 Check-in 후 안내에 따라 참가해주시기 바랍니다.</p>
                <p className='mb15 sp_p mb40'><span className='sp_sp'>-</span> 다음 예약된 VIP tour를 위해 반드시 시간을 준수해주십시오.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>

            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10 ssone sp_h'><span className="ft_blue ft_700">Check-in</span>  &nbsp;→&nbsp; Greeting  &nbsp;→&nbsp; One Samsung Vision  &nbsp;→&nbsp; Why SmartThings &nbsp;→&nbsp; Home AI &nbsp;→&nbsp; Beyond Home &nbsp;→&nbsp; <span className="ft_blue ft_700">Check-out</span></p>
              </div>
            </div>

          </section>
        )
      }
    </>
  );
}
