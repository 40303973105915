// const createTimeSlots = (date, startHour, endHour) => {
//   const slots = [];
//   let currentTime = new Date(`2025-01-${String(date).padStart(2, '0')}T${String(startHour).padStart(2, '0')}:00:00`);
//   const endTime = new Date(`2025-01-${String(date).padStart(2, '0')}T${String(endHour).padStart(2, '0')}:00:00`);
  
//   while (currentTime < endTime) {
//     const startTimeStr = currentTime.toTimeString().substring(0, 5);
//     const endTime = new Date(currentTime.getTime() + 40 * 60000);
//     const endTimeStr = endTime.toTimeString().substring(0, 5);
    
//     slots.push({
//       date: `2025-01-${String(date).padStart(2, '0')}`,
//       time: `${startTimeStr} ~`,
//       label: `${startTimeStr} ~ ${endTimeStr}`
//     });
    
//     currentTime = new Date(currentTime.getTime() + 40 * 60000);
//   }
//   return slots;
// };

// const tourTime = [
//   // 1월 7일 (10am - 6pm)
//   ...createTimeSlots(7, 10, 18),
  
//   // 1월 8-9일 (9am - 6pm)
//   ...createTimeSlots(8, 9, 18),
//   ...createTimeSlots(9, 9, 18),
  
//   // 1월 10일 (9am - 4pm)
//   ...createTimeSlots(10, 9, 16)
// ];

const mergeTourTimes = (times1, times2) => {
  // 모든 시간을 하나의 배열로 합치기
  const allTimes = [...times1, ...times2];
  
  // 중복 제거를 위한 Set 사용
  // date와 time을 조합한 유니크한 키를 만들어서 중복 체크
  const uniqueTimes = Array.from(
    new Map(
      allTimes.map(item => [`${item.date}-${item.time}`, item])
    ).values()
  );
  
  // 날짜와 시간으로 정렬
  const sortedTimes = uniqueTimes.sort((a, b) => {
    // 먼저 날짜로 정렬
    const dateCompare = a.date.localeCompare(b.date);
    if (dateCompare !== 0) return dateCompare;
    
    // 날짜가 같으면 시간으로 정렬
    return a.time.localeCompare(b.time);
  });
  
  return sortedTimes;
};

const tourTime1 = [
  ...[7].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T10:00:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T17:00:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  }),
  
  ...[8, 9].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T09:00:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T18:00:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  }),
  
  ...[10].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T09:00:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T15:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  })
];

const tourTime2 = [
  ...[7].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T10:15:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T17:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  }),
  
  ...[8, 9].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T09:15:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T17:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  }),
  
  ...[10].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T09:15:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T15:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  })
];

const combinedTourTime = mergeTourTimes(tourTime1, tourTime2);

export default combinedTourTime;
