const tourTime = [
  ...[7].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T10:15:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T17:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  }),
  
  ...[8, 9].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T09:15:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T17:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  }),
  
  ...[10].flatMap(day => {
    const slots = [];
    let currentTime = new Date(`2025-01-${String(day).padStart(2, '0')}T09:15:00`);
    const endTime = new Date(`2025-01-${String(day).padStart(2, '0')}T15:16:00`);
    
    while (currentTime < endTime) {
      const startTimeStr = currentTime.toTimeString().substring(0, 5);
      const endTime = new Date(currentTime.getTime() + 45 * 60000);
      const endTimeStr = endTime.toTimeString().substring(0, 5);
      
      slots.push({
        date: `2025-01-${String(day).padStart(2, '0')}`,
        time: `${startTimeStr} ~`,
        label: `${startTimeStr} ~ ${endTimeStr}`
      });
      
      currentTime = new Date(currentTime.getTime() + 45 * 60000);
    }
    return slots;
  })
];

export default tourTime;
