console.log(process.env);

const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;
const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST; 

const config = {
  API_SERVER_HOST,
  WEBSOCKET_HOST
}

export default config;