import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { setIsLoading } from '@/actions/common';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import tourTimeData from '@/data/tourTime';
import tourKoreanTimeData from '@/data/tourKoreanTime';
import tourEnglishTimeData from '@/data/tourEnglishTime';
import ReactToPrint from 'react-to-print';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { toJpeg } from 'html-to-image';

import { reservation, allReservations, approveReservation, declineReservation, sendMailReservationStatus, deleteManyReservation, downloadExcel } from '@/actions/tour';

export default function AdminTour() {
  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const tableRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10000);

  const [timeTableStatus, setTimeTableStatus] = useState(null);
  const [searchDate, setSearchDate] = useState('');
  const [filterApproved, setFilterApproved] = useState(true);
  const [filterVVIP, setFilterVVIP] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isTourView, setIsTourView] = useState(false);

  const { basePath } = useSelector((state) => state.common);

  useEffect(() => {
    let searchDate = Object.keys(_.groupBy(tourTimeData, row => row.date))[0];
    setSearchDate(searchDate);
  }, []);

  const getReservations = () => {
    dispatch(setIsLoading(true));
    dispatch(allReservations({
      page: currentPage,
      limit: pageLimit,
      searchDate: searchDate,
    }))
    .then(res => {
      let tourTimeTable = _.groupBy(tourTimeData, row => row.date);
      let koreanTimeTable = _.groupBy(tourKoreanTimeData, row => row.date);
      let englishTimeTable = _.groupBy(tourEnglishTimeData, row => row.date);
      let timeTableStatus = [];
      for(let timeIndex=0; timeIndex<tourTimeTable[searchDate].length;timeIndex++){
        timeTableStatus.push({
          k_time: koreanTimeTable[searchDate][timeIndex], 
          k1: _.filter(res.docs, row => row.time === koreanTimeTable[searchDate][timeIndex]?.time && row.personInCharge === 'k1' && ((filterApproved && row.status === 'Approved') || (filterVVIP && row.isVVIP))),
          k2: _.filter(res.docs, row => row.time === koreanTimeTable[searchDate][timeIndex]?.time && row.personInCharge === 'k2' && ((filterApproved && row.status === 'Approved') || (filterVVIP && row.isVVIP))),
          k3: _.filter(res.docs, row => row.time === koreanTimeTable[searchDate][timeIndex]?.time && row.personInCharge === 'k3' && ((filterApproved && row.status === 'Approved') || (filterVVIP && row.isVVIP))),
          k_plus: _.filter(res.docs, row => row.time === koreanTimeTable[searchDate][timeIndex]?.time && row.language === 'Korean' && row.status === 'In Progress'),
          e_time: englishTimeTable[searchDate][timeIndex], 
          e1: _.filter(res.docs, row => row.time === englishTimeTable[searchDate][timeIndex]?.time && row.personInCharge === 'e1' &&  ((filterApproved && row.status === 'Approved') || (filterVVIP && row.isVVIP))),
          e2: _.filter(res.docs, row => row.time === englishTimeTable[searchDate][timeIndex]?.time && row.personInCharge === 'e2' &&  ((filterApproved && row.status === 'Approved') || (filterVVIP && row.isVVIP))),
          e3: _.filter(res.docs, row => row.time === englishTimeTable[searchDate][timeIndex]?.time && row.personInCharge === 'e3' &&  ((filterApproved && row.status === 'Approved') || (filterVVIP && row.isVVIP))),
          e_plus: _.filter(res.docs, row => row.time === englishTimeTable[searchDate][timeIndex]?.time && row.language === 'English' && row.status === 'In Progress'),
        })
      }
      console.log(timeTableStatus)
      setTimeTableStatus(timeTableStatus);
      dispatch(setIsLoading(false));
    })
  }

  useEffect(() => {
    if(searchDate){
      setCurrentPage(1);
      getReservations();
    }
 },[searchDate, filterApproved, filterVVIP])


 const onOpenItem = (tour_id) => {
    dispatch(reservation(tour_id))
    .then((res) => {
      setSelectedItem(res);
      setIsTourView(true);
    })
  }

  const renderCard = (row) => {
    return (
      <table className='rsv_item'>
        <tr>
          <td colSpan={2} className='fc_white'><div className={`cal_table_status status_wrap ${row.status !== 'Approved' ? `bg_black` : 'bg_blue'} ${row.isVVIP?'vvip':''}`}>{row.status}</div></td>
        </tr>
        <tr>
          <td colSpan={2}>
          <p className='mb10'><strong>{row.guests[0].company}</strong></p>
          <p className='mb10'>{row.guests[0].name} / <br/>{row.guests[0].position}</p>
          <p className='mb10'>({row.inviteNumber})</p>
          <span style={{color:'#1428A0'}}>{row.host.executiveName} /<br/>{row.host.executivePosition}</span>
          </td>
        </tr>
        <tr className='cal_table_btns'>
          <td className=''>
            <Link to={basePath ? `/${basePath}/admin/tour?searchId=${row.id}` : `/admin/tour?searchId=${row.id}`}><button className="btn-td-sm" style={{marginRight:'-15px'}}><span className="sssb">View</span></button></Link>
          </td>
          <td className=''>
            <Link to={basePath ? `/${basePath}/admin/tour/${row.id}/modify?via=calendar` : `/admin/tour/${row.id}/modify?via=calendar`}><button className="btn-td-sm btn-td-sm--blue" style={{marginLeft:'-15px'}}><span className="sssb">Modify</span></button></Link>
          </td>
        </tr>
      </table>
    )
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Users table',
    sheet: 'Users'
  })

  const onButtonClick = useCallback(() => {
    if (tableRef.current === null) {
      return
    }

    toJpeg(tableRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'VIP Tour.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [tableRef])

  return (
    <>
      <div className='container_adm tour_calendar'>
        <div className=''>
          <div className='search_wrap' style={{alignItems:'center',paddingBottom:16,marginBottom: 44}}>
            <select onChange={e=>setSearchDate(e.target.value)} className='select_adm mr30' style={{width: 184}}>
              {
                Object.keys(_.groupBy(tourTimeData, row => row.date)).map((time) => (
                  <option value={time} selected={searchDate===time}><Moment format='DD.MMM.YYYY'>{time}</Moment></option>
                ))
              }
            </select>
            <div className='mr20' style={{display:'flex'}}>
              {/* <span className='bg_blue' style={{width:'38px', height:'38px', marginRight: '5px', display: 'inline-block'}}></span> */}
              <input type='checkbox'  id="cb_approve" onChange={e=>setFilterApproved(!filterApproved)} checked={filterApproved} readOnly/>
              <label for="cb_approve" style={{alignSelf: 'center', marginLeft:'10px'}}>Approved</label>
            </div>
            <div className='' style={{display:'flex'}}>
              <input type='checkbox'  id="cb_priority" onChange={e=>setFilterVVIP(!filterVVIP)} checked={filterVVIP} readOnly/>
              {/* <span className='bg_green' style={{width:'38px', height:'38px', marginRight: '5px', display: 'inline-block'}}></span> */}
              <label for="cb_priority" style={{alignSelf: 'center', marginLeft:'10px'}}>VVIP</label>
            </div>
          </div>
          <div className='adm_title-wrap mb20'>
            <h2 style={{display:'inline-block'}}>VIP tour approved calendar</h2>
            <button className='btn-excel' onClick={()=>dispatch(downloadExcel({"isCal" : true, "searchDate" : searchDate }))}>Export to Excel</button>
          </div>
          <table className="table_adm mb40" ref={tableRef} style={{backgroundColor:'#fff'}}>
            <thead>
              <tr>
                <th scope="col" colSpan={searchDate !== '2025-01-10' ? 5 : 4}>Korean</th>
                <th scope="col" colSpan={searchDate !== '2025-01-10' ? 5 : 3}>English</th>
              </tr>
              <tr>
                <th scope='col' className='two' >Time</th>
                <th scope='col' className='two' >Kor1</th>
                <th scope='col' className='two' >Kor2</th>
                {searchDate !== '2025-01-10' && <th scope='col' className='two' >Kor3</th> }
                <th scope='col' className='two'>승인대기</th>
                <th scope='col' className='two' >Time</th>
                <th scope='col' className='two' >Eng1</th>
                {searchDate !== '2025-01-10' && <>
                <th scope='col' className='two' >Eng2</th>
                <th scope='col' className='two' >Eng3</th>
                </>}
                <th scope='col' className='two'>승인대기</th>
              </tr>
            </thead>
            <tbody>
              {
                timeTableStatus && timeTableStatus.map((item, index)=>(
                  <tr key={index}>
                    <td style={{width: 108,lineHeight:2}} dangerouslySetInnerHTML={{__html: item.k_time?.label.replace("~","<br>~<br>")}}></td>
                    <td className='two'><div className='cal_table_wrap td_mh'>
                      {
                        item.k1 && item.k1.map((row, index)=>(
                          <>
                          {renderCard(row)}
                          </>
                        ))
                      }
                      </div>
                    </td>
                    <td className='two'><div className='cal_table_wrap td_mh'>
                      {
                        item.k2 && item.k2.map((row, index)=>(
                          <>
                          {renderCard(row)}
                          </>
                        ))
                      }
                      </div>
                    </td>
                    {searchDate !== '2025-01-10' && <td className='two'><div className='cal_table_wrap td_mh'>
                      {
                        item.k3 && item.k3.map((row, index)=>(
                          <>
                          {renderCard(row)}
                          </>
                        ))
                      }
                      </div>
                    </td>}
                    <td className='two'><div className='cal_plus_wrap td_mh'>
                      {
                        item.k_plus && item.k_plus.length ? (
                          <Link to={basePath ? `/${basePath}/admin/tour?language=Korean&date=${item.k_time?.date}&time=${item.k_time?.time}&status=In Progress,Priority` : `/admin/tour?language=Korean&date=${item.k_time?.date}&time=${item.k_time?.time}&status=In Progress,Priority`}><button className="btn-td-more">Click<span>{item.k_plus.length}</span></button></Link>
                        ) : null
                      }</div>
                    </td>
                    <td style={{width: 108,lineHeight:2}} dangerouslySetInnerHTML={{__html: item.e_time?.label.replace("~","<br>~<br>")}}></td>
                    <td className='two'><div className='cal_table_wrap td_mh'>
                    {
                        item.e1 && item.e1.map((row, index)=>(
                          <>
                          {renderCard(row)}
                          </>
                        ))
                      }</div>
                    </td>
                    {searchDate !== '2025-01-10' && <>
                    <td className='two'><div className='cal_table_wrap td_mh'>
                    {
                        item.e2 && item.e2.map((row, index)=>(
                          <>
                          {renderCard(row)}
                          </>
                        ))
                      }</div>
                    </td>
                    <td className='two'><div className='cal_table_wrap td_mh'>
                    {
                        item.e3 && item.e3.map((row, index)=>(
                          <>
                          {renderCard(row)}
                          </>
                        ))
                      }</div>
                    </td>
                    </>}
                    <td className='two'><div className='cal_plus_wrap td_mh'>
                      {
                        item.e_plus && item.e_plus.length ? (
                          <Link to={basePath ? `/${basePath}/admin/tour?language=English&date=${item.e_time?.date}&time=${item.e_time?.time}&status=In Progress,Priority` : `/admin/tour?language=English&date=${item.e_time?.date}&time=${item.e_time?.time}&status=In Progress,Priority`}><button className="btn-td-more">Click<span>{item.e_plus.length}</span></button></Link>
                        ) : null
                      }</div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className='modal fade in' id='modalTicketView' style={{display: isTourView ? 'block' : 'none' }}>
        <div className='modal-dialog modal-dialog_xlg modal-dialog-centered'>
          <div className='modal-content'>
            <ReactToPrint
              trigger={() => <button className='btn-print'></button>}
              content={() => componentRef.current}
            />
            <button className='btn-modalclose' onClick={()=>setIsTourView(false)}></button>
            <div ref={componentRef}>
              <div className='modal-body'>
                {
                  selectedItem &&
                  (
                    <div className="form">
                      <div className="vip-reservation-tit mb30" style={{position: 'relative'}}>
                        <label htmlFor='tourMemo' className={`tour_memo open`}  data-color={selectedItem?.label?.bgColor}>
                          {selectedItem?.label?.text ? selectedItem?.label?.text : 'Label'}
                        </label>
                      </div>
                      <div className='form_inner'>
                        <div className='form_item'>
                          {selectedItem?.label?.text ? selectedItem?.label?.text : 'Label'}
                        </div>
                      </div>
                      <div className='form_inner mb60'>
                        <div className="form_item">
                            {selectedItem?.memo}
                        </div>
                      </div>
                    <div className="vip-reservation-tit mb30">
                      <h4>Tour request</h4>
                      <span className="tit-desc"></span>
                    </div>
                    <div className="form_inner mb100">
                      <div className="form_item">
                        <label htmlFor="host_dept" className="list_label">Tour language<span></span>투어 언어</label>
                        <p className="list_info">{selectedItem.language}</p>
                      </div>
                      <div className="form_item">
                        <label className="list_label">Date & Time<span></span>날짜 & 시간</label>
                        <div className="form_info">
                          <p className="list_info"><Moment format='DD.MMM.YYYY'>{selectedItem.date}</Moment></p>
                          <p className="list_info">{selectedItem.time}</p>
                        </div>
                      </div>
                    </div>
                    <div className="vip-reservation-tit mb30">
                      <h4>VIP tour PIC</h4>
                    </div>
                    <div className="form_inner mb100">
                      <div className="form_item">
                        <label htmlFor="roomhost_name" className="list_label">Name<span></span>이름</label>
                        <p className="list_info">{selectedItem.pic.firstName} {selectedItem.pic.lastName}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="memb_region" className="list_label">Region / Organization<span></span>지역 / 법인</label>
                        <p className="list_info">{selectedItem.pic.region} / {selectedItem.pic.organization} </p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="memb_org" className="list_label">Division<span></span>사업부</label>
                        <p className="list_info">{selectedItem.pic.gbm}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="memb_email" className="list_label">Department<span></span>부서</label>
                        <p className="list_info">{selectedItem.pic.department}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="memb_email" className="list_label">Email<span></span>이메일</label>
                        <p className="list_info">{selectedItem.pic.email}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="memb_ph" className="list_label">Mobile contact point<span></span>연락처</label>
                        <p className="list_info">{selectedItem.pic.countryCode} {selectedItem.pic.mobile}</p>
                      </div>   
                    </div>
                    <div className="vip-reservation-tit mb30">
                      <h4>On-site contact</h4>
                    </div>
                    <div className="form_inner mb100">
                      <div className="form_item">
                        <label htmlFor="atte_memb" className="list_label">Name<span></span>이름</label>
                        <p className="list_info">{selectedItem?.site?.firstName} {selectedItem?.site?.lastName}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="att_company" className="list_label">Mobile contact point<span></span>연락처</label>
                        <p className="list_info">{selectedItem?.site?.countryCode} {selectedItem?.site?.mobile}</p>
                      </div>
                    </div>
                    <div className="vip-reservation-tit mb30">
                      <h4>Samsung host</h4>
                    </div>
                    <div className="form_inner mb100">
                      <div className="form_item">
                        <label htmlFor="atte_memb" className="list_label">Department<span></span>소속</label>
                        <p className="list_info">{selectedItem.host.department}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="att_company" className="list_label">Executive name<span></span>성함</label>
                        <p className="list_info">{selectedItem.host.executiveName}</p>
                      </div>
                      <div className="form_item">
                        <label htmlFor="att_company" className="list_label">Executive position<span></span>직위</label>
                        <p className="list_info">{selectedItem.host.executivePosition}</p>
                      </div>
                    </div>
                    <div className="vip-reservation-tit mb30">
                      <h4>VIP guests</h4>
                    </div>
                    <div className="form_inner mb40">
                      <div className="form_item">
                        <label htmlFor="atte_memb" className="list_label">Number of invitees<span></span>초대받은 인원 수</label>
                        <p className="list_info">{selectedItem.inviteNumber}</p>
                      </div>
                      <div className="form_item mb40">
                        <label htmlFor="atte_memb" className="list_label">Number of Samsung employees<span></span>동행 삼성 임직원 수</label>
                        <p className="list_info">{selectedItem.inviteSamsungEmployeesNumber}</p>
                      </div>
                      <ul className="modal_view clear mb80">
                        {/* {
                          selectedItem.guests.map((item, i)=>(
                            <div className="vip_invitee" key={i}>
                              <strong>No.{i+1}</strong>
                              <div>
                                {item.name}
                              </div>
                              <div>
                                {item.company}
                              </div>
                              <div>
                                {item.position}
                              </div>
                            </div>
                          ))
                        } */}
                        <li className="view-four mr20" style={{width: 128}}>
                          <small>No.</small>
                        </li>
                        <li className="view-four mr20">
                          <small>Name</small>
                        </li>
                        <li className="view-four mr20">
                          <small>Company</small>
                        </li>
                        <li className="view-four">
                          <small>Position</small>
                        </li>
                        {
                          selectedItem.guests.map((guest,i)=>(
                            <>
                              <li className="view-four mr20" style={{width: 128}}>
                                <span className="modal_value">{i+1}</span>
                              </li>
                              <li className="view-four mr20">
                                <span className="modal_value">{guest.name}</span>
                              </li>
                              <li className="view-four mr20">
                                <span className="modal_value">{guest.company}</span>
                              </li>
                              <li className="view-four">
                                <span className="modal_value">{guest.position}</span>
                              </li>
                            </>
                          ))
                        }
                      </ul>
                      <div className="form_item" style={{width: '100%',flexDirection:'column'}}>
                        <label htmlFor="currentBusinessIssues" className={`current_issues list_label`} style={{width:'100%',maxWidth:'100%',marginRight:0,marginBottom:24}}>
                          - 당사와의 관계 또는 연관된 비즈니스 현안 <i>(예- 북미 디스플레이 사업 당사 제1거래선)</i><br/>
                          - Business relations to Samsung or relevant business issues <i>(e.g. The 1st partner on display business in North America)</i>
                        </label>
                        <p className="list_info">{selectedItem.currentBusinessIssues}</p>
                      </div>
                    </div>
                  </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
    </div>
    </>
  );
}
