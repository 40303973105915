const faqEN = [
  {
    categoryCode: 'use',
    category: 'General',
    question: 'Is login required to use the site?',
    answer: 'Yes. Registration and login are required to access the site.<br/>Please register using your Knox email address before using the service.',
  },
  {
    categoryCode: 'use',
    category: 'General',
    question: 'Is registration limited to Samsung.com email addresses?',
    answer: `Yes, the reservation system is exclusively for samsung employees and partners.<br/>Use a samsung.com or partner.samsung.com email address and check the approval email before using the service`,
  },
  {
    categoryCode: 'use',
    category: 'General',
    question: 'Is separate registration needed for Business lounge, VIP tour, and CES badge?',
    answer: 'No, registration for Business lounge and VIP tour is integrated on this site.<br/>However, CES Badge applications must be completed on separate platform, following the instruction provided.',
  },
  {
    categoryCode: 'use',
    category: 'General',
    question: 'Is new registration required for each event, such as CES, MWC, or IFA?',
    answer: 'Yes, as no information is stored, registration must be completed separately for each event.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[General] What are the steps to apply for a ticket?',
    answer: `With a time difference, you must go through two application processes to complete the ticket issuance. (1) Sign up for a voucher code at the address below (<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>).
(2) The voucher code will be issued on the announced date with the notice mail.<br/>
You must register this code directly on the IFA official website (<a class="ssone" href="https://ifa-berlin-2023.reg.buzz/" tagret="_blank">https://ifa-berlin-2023.reg.buzz/</a>) to obtain a final ticket in PDF format.<br/>
If you have completed the last IFA stage and have not received a PDF ticket, please note that ticket issuance is not possible at the IFA event venue.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[General] What do I need to enter the Berlin IFA event venue?',
    answer: 'Please print the ticket issued on the IFA official website in PDF form or save it on your mobile device and bring it to the venue. At the last step of registering the ticket, (Order Confirmed page) on the IFA official website (<a class="ssone" href="https://ifa-berlin-2023.reg.buzz/" tagret="_blank">https://ifa-berlin-2023.reg.buzz/</a>), click the [Download ticket] or [Mobile ticket] button to save the ticket PDF.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[General] When will the voucher code be distributed?',
    answer: 'A link will be sent on the announced date, When issuing a ticket, a link (go to site) will be distributed to the mail address registered in [Ticket Request].',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[General] Is there an additional charge when I apply for the ticket?',
    answer: 'No. This event is for executives and employees, so there is no additional charge.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Subject of Voucher issuance] I\'d like to know the scope of the person who can get the ticket.',
    answer: 'This ticket request applies to employees who are engaged in an exhibition related to IFA 23 Samsung Electronics. Applications for non-exhibition-related work at Samsung Hall or personnel other than executives and employees are prohibited. Also, tickets for separate corporate personnel are not supported.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Subject of Voucher issuance] I\'m a subject of voucher issuance, but I didn\'t get a voucher code issuance mail. Is there an additional way to apply?',
    answer: 'If you have not received the voucher code approval mail until the 2nd issue date, please contact the person in charge (Eun-seob Kim Pro of Cheil Worldwide).',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[proxy request] Is it possible to apply on behalf of someone else?',
    answer: `Yes, you can apply for someone else at the address below (<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>) by adding the applicant information by clicking the [+] button at the bottom of the [Ticket Request] page.<br/>In the case of Samsung event website\s mailing service, it will be registered based on the applicant\s mail address information, not the subject, so please keep this in mind if you register on behalf of the IFA official website later. Also, if you register a person other than the person in charge of the Samsung event or apply for a false proxy application, please note that we will request and notify the business department about the matter.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[proxy request] Is there anything to keep in mind when apply on behalf of someone else?',
    answer: 'When registering a voucher code on the IFA official website, there is a step to authenticate the 6-digit code with the mail of the subject that you filled out on that IFA official website.<br/>For quick application, please maintain real-time contact between the applicant and the subject at the IFA stage.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] What is the difference between [Exhibiting Client] and [Stand Maintenance badges]?',
    answer: '[Exhibiting Client] allows you to enter during the exhibition period, and [Stand Maintenance badges] allows you to enter during the period of construction and demolition, (excluding exhibition period)',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] Which one should I get issued, [Exhibiting Client] or [Stand Maintenance badges]?',
    answer: 'The [Exhibiting Client] includes the [Stand Maintenance badges] permission.<br/>For those who need access only during the preparation period, please check the Stand Maintenance badges and issue it.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] Is it possible to enter the venue during periods other than the IFA official exhibition period (September 1st to September 5th)?',
    answer: '[Exhibiting Client] includes [Stand Maintenance badges] permission. During the construction period before and after the exhibition period (August 31st / September 5th at 6 p.m.), admission is possible without restrictions on access time.<br/>However, if you work late at night, please share it with the organizer in advance.',
  },
  // {
  //   categoryCode: 'ticket',
  //   category: 'Ticket',
  //   question: '[Ticket Type] I received both [Exhibitor Ticket] and [Build-up Ticket] voucher codes. If one ticket type is enough for me, is it okay to register only one voucher code on the IFA official website?',
  //   answer: 'Yes, you can register only one of the codes you need and use it.',
  // },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Cancellation of Reservation] business trip has been canceled. How can I cancel it?',
    answer: `If you have only completed the application within this website, no separate cancellation process is required. However, cancellation is not possible on the official registration site, and it will be included in the billing of each business unit, so please proceed carefully after the business trip is confirmed.`
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Modify reservation contents] The applicant information was entered incorrectly. How can I modify it?',
    answer: `If you enter the wrong information on the Samsung event website, please cancel and re-register only if it is the cancel button with a black background on the address below (<a class="ssone" href="${window.location.origin}/ticket/ticket-list" tagret="_blank">${window.location.origin}/ticket/ticket-list</a>). If the cancel button has already been changed to a gray background and it is impossible to click, please contact the person in charge (Eun-seob Kim of Cheil Worldwide).`
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: 'Is there any additional cost for making a reservation?',
    answer: 'No. It is for executives and employees, so there is no additional charge.',
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: 'What are the available times for reservations?',
    answer: 'Reservations are available in 30-minute increments, with a maximum of 1 hour per day.',
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: 'How can I make a reservation?',
    answer: `Reservations are made by Please click on the available time at the address below (<a href="${window.location.origin}/room/reservation/timetable" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/reservation/timetable</a>) and complete the required items on the reservation page. Once the reservation is completed, the reservation will be finally approved by the administrator. Once your reservation is finally approved, an approval email will be sent to the email you applied for, so please check your registered email.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: 'How can I check the status of my reservation?',
    answer: `Reservation details can be viewed all at once through this link(<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) A confirmation email will be sent after the administrator’s final approval is completed.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: 'Information entered incorrectly. How do I fix it?',
    answer: `At the link below (<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>)  you can modify the information you entered or cancel your reservation on the details page.<br/>However, after approval, the reservation date, time, and business lounge cannot be changed. In such cases, you will need to cancel and rebook. Please make your reservation carefully.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: 'Can I use it just by making a reservation?',
    answer: 'After making a reservation, usage is subject to manager approval, and your request may be declined depending on the reservation status. If time adjustments are necessary, the person in charge will contact you directly. Once your reservation is approved, an approval email will be sent to the email address you provided.<br/>Please check your registered email. Approval emails will be sent around late December to early January, reflecting the latest local conditions. If your schedule changes, please update or modify your reservation promptly to ensure smooth operation of the business lounge.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'Who is eligible to apply for the VIP tour, and are there any costs?',
    answer: 'There are no additional charge, but only exclusive guests who are hosted by Samsung executives; EVP, Head of Regional Office or higher.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'Is it possible to apply for multiple VIP tours?',
    answer: `Yes, applications can be submitted via this link (<a href="${window.location.origin}/viptour/reservation" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation</a>) <br/>Only applications that meet the eligibility criteria will receive a final approval email. Please check the follow-up email for further details.`,
  },  
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'Is the number of participants per VIP tour limited?',
    answer: 'Tours are limited to 15 people to maintain a pleasant tour experience.<br/>Please provide the exact number of participants for equipment preparation.',
  },  
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'What language options are available for the VIP tour?',
    answer: 'VIP tours are available in Korean and English, and you can select one.',
  },  
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'How can I check the status of my VIP tour application?',
    answer: `Application details can be viewed all at once through this link (<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>) <br/>A confirmation email will be sent after the administrator’s final approval is completed.`,
  },  
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'How can I edit incorrect information before or after approval?',
    answer: `Before approval: Edit or cancel details directly on the Reservation list (<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>) <br/>After approval: If unable to attend, contact the representatives below promptly.<br/>Heali Hwang : <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>, Minyoung Park : <a href="mailto:my1011.park@samsung.com" style="color:#1268bd;text-decoration:underline">my1011.park@samsung.com</a>`,
  }


];

export default faqEN;